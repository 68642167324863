import React, { useEffect, useState } from "react";
import PrivateComponent from "components/PrivateComponent";
import axios from 'axios';
import { Spin } from "antd";



const PureHeaderInfo = () => {
	const [ state, setState ] = useState({
		credit_limit: '--',
		available_credit: '--',
		company_name: '',
		loading: true,
	});

	useEffect(() => {
		async function getHeaderInfo() {
			try {
				let result = await axios.get(process.env.REACT_APP_API_URL + '/account/header_info');
				setState({
					credit_limit: result.data.credit_limit,
					available_credit: result.data.available_credit,
					company_name: result.data.company_name,
					loading: false,
				});
			} catch(err) {
				setTimeout(() => {
					getHeaderInfo();
				}, 30000);
			}

		}

		getHeaderInfo();
	}, [ setState ]);

	if(state.loading) {
		return (
			<div style={{ height: 112 }}>
				<Spin size="large" className="header-company-name" />
			</div>
		);
	}

	return (
		<div>
			<div style={{ height: 60 }}>
				<strong className="header-company-name">{state.company_name}</strong>
			</div>
			<div>
				<PrivateComponent>
					<strong className="header-credit-limit">Credit Limit:<br />{state.credit_limit}</strong>
					<strong className="header-credit-limit">Available Credit:<br />{state.available_credit}</strong>
				</PrivateComponent>
			</div>
		</div>
	);
};



export default PureHeaderInfo;
