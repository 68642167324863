import axios from 'axios';
import { message } from 'antd';



const initialState = {
	loading: false,
	showModal: false,
	modalDocNum: null,
	modalDocEntry: null,
	modalLoading: false,
	documents: [],
	documentsAll: [],
	pdfs: {},
	selectedRows: [],
};


export const types = {
	SET_STATE: 'EFTS/SET_STATE',
};




export default (state = initialState, action) => {
	switch(action.type) {
		case types.SET_STATE:
			return { ...state, ...action.data };
		default:
			return state;
	}
}



export const actions = {
	setState: (data) => ({ type: types.SET_STATE, data }),


	resetAll: () => ({ type: types.SET_STATE, data: { ...initialState } }),


	resetModal: () => ({
		type: types.SET_STATE,
		data: { showModal: false, modalDocNum: null, modalDocEntry: null, modalLoading: false },
	}),


	refreshDocuments: (silent = false) => async (dispatch) => {
		dispatch({ type: types.SET_STATE, data: { loading: true } });

		try {
			let request = await axios.get(`${process.env.REACT_APP_API_URL}/efts?silent=${silent.toString()}`);



			dispatch({
				type: types.SET_STATE, data: {
					loading: false,
					documents: request.data.efts,
					documentsAll: request.data.efts,
				},
			});
		} catch(err) {
			dispatch({ type: types.SET_STATE, data: { loading: false } });
		}
	},



	viewPDF: (docEntry, docNum, silent = false) => async (dispatch, getState) => {
		let hasPDF = Boolean(getState().efts.pdfs[ docEntry ]);

		dispatch({
			type: types.SET_STATE, data: {
				modalLoading: !hasPDF,
				modalDocEntry: docEntry,
				modalDocNum: docNum,
				showModal: true,
			},
		});

		if(hasPDF) {
			return true;
		}

		try {
			let pdfRequest = await axios.get(`${process.env.REACT_APP_API_URL}/efts/view/${docEntry}?silent=${silent.toString()}`, { timeout: 60000 });

			dispatch({
				type: types.SET_STATE, data: {
					modalLoading: false,
					pdfs: { ...getState().efts.pdfs, [ docEntry ]: pdfRequest.data.fileData },
				},
			});
		} catch(err) {
			if(err.message && err.message.indexOf('timeout') !== -1) {
				message.error('PDF fetching timed out after 1 minute. Please try again later.');
			}
			dispatch(actions.resetModal());
		}
	},
};