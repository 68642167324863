import { useSelector } from 'react-redux';



const PrivateComponent = ({ children }) => {
	const loggedIn = useSelector(state => state.auth.loggedIn);
	return loggedIn ? children : null;
};




export default PrivateComponent;