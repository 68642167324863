import makeAntColumns from "lib/makeAntColumns";



export const columns = makeAntColumns([
	{
		title: 'Member',
		dataIndex: 'company_name',
		sorter: 'string',
	},
	{
		title: 'Period',
		dataIndex: 'period',
		sorter: 'string',
	},
	/*{
		title: 'Supplier',
		dataIndex: 'supplier',
		sorter: 'number',
	},*/
	{
		title: 'Product',
		dataIndex: 'product_group',
		sorter: 'number',
	},
	{
		title: 'Terminal ID #',
		dataIndex: 'terminal',
		sorter: 'string',
	},
	{
		title: 'Terminal Name',
		dataIndex: 'terminal_name',
		sorter: 'string',
	},


	{
		title: 'Daily Max',
		dataIndex: 'daily_allocation',
		sorter: 'string',
	},
	{
		title: 'Lifted Today',
		dataIndex: 'daily_lifted_gross',
		sorter: 'string',
	},
	{
		title: 'Temp Daily Override',
		dataIndex: 'temp_daily_allocation',
		sorter: 'string',
	},
	{
		title: 'Daily Remaining',
		dataIndex: 'daily_allocation_remaining',
		sorter: 'string',
	},


	{
		title: 'Weekly Max',
		dataIndex: 'weekly_allocation',
		sorter: 'string',
	},
	{
		title: 'Lifted This Week',
		dataIndex: 'weekly_lifted_gross',
		sorter: 'string',
	},
	{
		title: 'Temp Weekly Override',
		dataIndex: 'temp_weekly_allocation',
		sorter: 'string',
	},
	{
		title: 'Weekly Remaining',
		dataIndex: 'weekly_allocation_remaining',
		sorter: 'string',
	},


	{
		title: 'Monthly Max',
		dataIndex: 'normal_monthly_allocation',
		sorter: 'string',
	},
	{
		title: 'Lifted This Month',
		dataIndex: 'monthly_lifted_gross',
		sorter: 'string',
	},
	{
		title: 'Temp Monthly Override',
		dataIndex: 'temp_monthly_allocation',
		sorter: 'string',
	},
	{
		title: 'Monthly Remaining',
		dataIndex: 'remaining_monthly_allocation',
		sorter: 'string',
	},


	/*{
		title: 'Status',
		dataIndex: 'destination',
		sorter: 'string',
	},*/
]);




export default { columns };