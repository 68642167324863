import React from 'react';
import { Layout as AntLayout, Menu } from 'antd';
import logo from 'images/logo.png';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PrivateComponent from "components/PrivateComponent";
import PureHeaderInfo from 'components/PureHeaderInfo';



const { Header, Footer, Content } = AntLayout;
const year = new Date().getFullYear();



const Layout = (props) => {
	const loggedIn = useSelector(state => state.auth.loggedIn);

	return (
		<div>
			<AntLayout>
				<Header className="header">
					<div className="ts-header-container">
						<Link className="header-logo" to="/">
							<img src={logo} alt="Pure Fuel" className="header-logo" />
						</Link>
						<PrivateComponent>
							<PureHeaderInfo />
						</PrivateComponent>
					</div>


				</Header>
				<Header className="nav">

					{loggedIn === true &&
					<Menu
						mode="horizontal"
						className="nav-menu"
						selectedKeys={[ 'account' ]}
					>
						<Menu.Item key="home"><Link to="/">Home</Link></Menu.Item>
						<Menu.SubMenu
							title="Invoices"
							popupOffset={[ 0, 2 ]}
							key="invoices"
						>
							<Menu.Item key="invoices_open"><Link to="/invoices/open">Open</Link></Menu.Item>
							<Menu.Item key="invoices_closed"><Link to="/invoices/closed">Closed</Link></Menu.Item>
							<Menu.Item key="invoices_view_all"><Link to="/invoices/all">View All</Link></Menu.Item>
						</Menu.SubMenu>

						<Menu.SubMenu
							title="Credits"
							popupOffset={[ 0, 2 ]}
							key="credits"
						>
							<Menu.Item key="credits_open"><Link to="/credits/open">Open</Link></Menu.Item>
							<Menu.Item key="credits_closed"><Link to="/credits/closed">Closed</Link></Menu.Item>
							<Menu.Item key="credits_view_all"><Link to="/credits/all">View All</Link></Menu.Item>
						</Menu.SubMenu>

						<Menu.Item key="eft"><Link to="/eft">EFTs</Link></Menu.Item>

						<Menu.SubMenu
							title="My Account"
							popupOffset={[ 0, 2 ]}
							key="account"
						>
							<Menu.Item key="account_allocations"><Link to="/allocations">Allocations</Link></Menu.Item>
							<Menu.Item key="account_profile"><Link to="/profile">Profile</Link></Menu.Item>
							<Menu.Item key="account_log_out"><Link to="/logout">Log Out</Link></Menu.Item>
						</Menu.SubMenu>
					</Menu>
					}
				</Header>

				<div className="nonheader_wrapper">
					<Content className="ts_content">
						{props.children}
					</Content>


					<Footer className="ts-footer" align="center">
						&copy; {year} PURE Oil Jobbers Cooperative, Inc. All rights reserved.
					</Footer>
				</div>
			</AntLayout>

		</div>
	);
};



export default Layout;