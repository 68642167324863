import React, { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { actions } from "base/store";
import { columns, closedColumns } from './Invoices.tableConfig';
import { Col, Row, Table, Typography } from 'antd';
import InvoicePDFModal from "./InvoicePDFModal";
import InvoiceSearchInput from './InvoiceSearchInput';
import ExportCSVButton from "components/ExportCSVButton";
import moment from "moment";



function getMode(mode) {
	switch((mode || '').toLowerCase()) {
		case 'open':
			return 'open';
		case 'closed':
			return 'closed';
		default:
			return 'all';
	}
}

const Invoices = () => {
	let mode = getMode(useParams().mode);
	const dispatch = useDispatch();

	const documents = useSelector(state => state.invoices.documents),
		  loading   = useSelector(state => state.invoices.loading);


	const refreshDocuments = useCallback((mode) => {
		dispatch(actions.invoices.refreshDocuments(mode));
	}, [ dispatch ]);

	const resetAll = useCallback(() => {
		dispatch(actions.invoices.resetAll());
	}, [ dispatch ]);


	useEffect(() => {
		refreshDocuments(mode);
		return () => resetAll();
	}, [ refreshDocuments, resetAll, mode ]);



	return (
		<div className="ts-home-content-container ts-portal-card">
			<Typography.Title level={2}>{mode.charAt(0).toUpperCase() + mode.slice(1)} Invoices</Typography.Title>
			<Row className="login-alert">
				<Col xs={24} md={12} lg={6}>
					<InvoiceSearchInput />
				</Col>

				<Col xs={24} md={12} lg={6}>
					<div className="ts_table_export_button">
						<ExportCSVButton
							columns={columns}
							rows={documents}
							fileName={`Pure Invoices ${moment().format('MM-DD-YYYY')}.csv`}
						/>
					</div>
				</Col>
			</Row>

			<div className="ts_scroll_x">
				<Table
					columns={mode === 'closed' ? closedColumns : columns}
					dataSource={documents}
					loading={loading}
					pagination={{
						position: 'both',
						showSizeChanger: true,
						defaultPageSize: 10,
						pageSizeOptions: [ '5', '10', '20', '50', '100' ],
					}}
				/>
			</div>


			<InvoicePDFModal />
		</div>
	);
};


export default Invoices;