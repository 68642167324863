const { Parser } = require('json2csv');



function antTableToCSV(columns, rows) {
	let fields = columns
		.filter(col => !Boolean(col.csvIgnore))
		.map(col => ({
			label: col.title || col.dataIndex,
			value: col.dataIndex,
		}));

	const json2csvParser = new Parser({ fields });

	console.log(json2csvParser.parse(rows));
	return json2csvParser.parse(rows);
}



export default antTableToCSV;