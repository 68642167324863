import React, { useState } from 'react';
import { Button, Form, Input, Typography } from 'antd';
import { LockOutlined } from '@ant-design/icons';
import axios from 'axios';



const emailValidator = {
	validator: (rule, value) => {
		if(/^\w+([+.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
			return Promise.resolve();
		}

		return Promise.reject('Please enter a valid email address');
	},
	validateTrigger: 'onSubmit',
};



const ForgotPassword = () => {

	const [ submitted, setSubmit ]      = useState(false),
		  [ submitting, setSubmitting ] = useState(false);


	const handleSubmit = async (values) => {
		setSubmitting(true);

		try {
			await axios.post(`${process.env.REACT_APP_API_URL}/user/request_password_reset`, { email: values.email });
			console.log('yas')
			setSubmit(true);
		} catch(err) {
			setSubmitting(false);
		}
	};


	if(submitted) {
		return (
			<div className="ts-rma-content-container">
				<div className="ts-portal-card">
					<Typography.Title level={4}><LockOutlined /> Forgot Password</Typography.Title>
					<Typography.Text>
						Your password reset request has been submitted. If an account exists with the provided email,
						you will receive an email with instructions on resetting your password in the next couple
						minutes. If the email does not arrive, please check your spam box to make sure it wasn't
						automatically marked as spam.
					</Typography.Text>
				</div>
			</div>
		);
	}

	return (
		<div className="ts-rma-content-container">
			<div className="ts-portal-card">
				<Typography.Title level={4}><LockOutlined /> Forgot Password</Typography.Title>
				<Typography.Text>
					Please enter your account email address below and click Submit. After submitting the form,
					you will receive an email with instructions on resetting your password.
				</Typography.Text>

				<Form
					name="order_approval"
					layout="horizontal"
					labelCol={{ xs: { span: 24 }, lg: { span: 6 } }}
					wrapperCol={{ xs: { span: 24 }, lg: { span: 18 } }}
					labelAlign="left"
					onFinish={handleSubmit}
					className="ts-form-margin"
				>

					<Form.Item
						name="email"
						label="Email Address"
						rules={[ { required: true, message: 'Email address is required' }, () => (emailValidator) ]}
					>
						<Input maxLength={100} />
					</Form.Item>

					<Form.Item>
						<Button type="primary" htmlType="submit" loading={submitting}>
							Submit
						</Button>
					</Form.Item>
				</Form>

			</div>
		</div>


	);
};


export default ForgotPassword;