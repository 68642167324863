import React, { useState } from 'react';
import { Button, Modal, Typography } from "antd";
import axios from 'axios';



const defaultState = {
	loading: false,
	finished: false,
	success: false,
};

const ChangePasswordModal = ({ visible, handleClose }) => {
	const [ state, setState ] = useState({ ...defaultState });


	const handleChangePassword = () => {
		setState({ ...state, loading: true });
		axios.put(`${process.env.REACT_APP_API_URL}/user/request_password_reset`).then(res => {
			setState({ ...state, loading: false, finished: true, success: true });
		}).catch(err => {
			setState({ ...state, loading: false, finished: true, success: false });
		});
	};


	const handleCloseModal = () => {
		setState({ ...defaultState });
		handleClose();
	};



	return (
		<Modal
			title={`Change Password`}
			visible={visible}
			onCancel={handleCloseModal}
			centered
			footer={[
				!state.finished
				? <Button key="sendReset" onClick={handleChangePassword} type="primary" disabled={state.loading}>
					Send Reset Email
				</Button>
				: null,
				<Button key="close" onClick={handleCloseModal} disabled={state.loading}>
					{state.finished ? 'Close' : 'Cancel'}
				</Button>,
			]}
		>

			{!state.finished &&
			<Typography.Text>
				If you wish to change your password, click the Sent Reset Email button below. You will be sent an
				email with a link to change your password.
			</Typography.Text>
			}


			{(state.finished && state.success) &&
			<Typography.Text>
				Your password change request has been received, and an email with instructions on changing your
				password should arrive in the next couple minutes. The link contained in the email is good for one hour.
			</Typography.Text>
			}

			{(state.finished && !state.success) &&
			<Typography.Text>
				There was an error with your password reset request. Please try again later.
			</Typography.Text>
			}
		</Modal>

	);
};



export default ChangePasswordModal;