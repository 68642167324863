import React, { useCallback, useEffect } from 'react';
import { Table, Typography } from 'antd';
import { columns } from './Allocations.tableConfig';
import { useDispatch, useSelector } from "react-redux";
import { actions } from 'base/store';
import ExportCSVButton from "components/ExportCSVButton";
import moment from "moment";



const Allocations = () => {
	const dispatch = useDispatch();

	const allocations = useSelector(state => state.allocations.rows),
		  loading     = useSelector(state => state.allocations.loading);

	const refreshAllocations = useCallback(() => {
		dispatch(actions.allocations.refreshAllocations());
	}, [ dispatch ]);

	const resetAllocations = useCallback(() => {
		dispatch(actions.allocations.resetAllocations());
	}, [ dispatch ]);


	useEffect(() => {
		refreshAllocations();
		return () => resetAllocations();
	}, [ resetAllocations, refreshAllocations ]);



	return (
		<div className="ts-allocations-content-container ts-portal-card">
			<Typography.Title level={2}>Allocations</Typography.Title>


			<div className="login-alert">
				<ExportCSVButton
					columns={columns}
					rows={allocations}
					fileName={`Pure Allocations ${moment().format('MM-DD-YYYY')}.csv`}
				/>
			</div>


			<div className="ts_scroll_x">
				<Table
					columns={columns}
					dataSource={allocations}
					className="ts_allocations"
					loading={loading}
					size={"small"}
					bordered
					scroll={{ y: 300, x: 1500 }}
					pagination={{
						position: 'both',
						showSizeChanger: true,
						defaultPageSize: 50,
						pageSizeOptions: [ '10', '20', '50', '100' ],
					}}
				/>
			</div>
		</div>
	);
};


export default Allocations;