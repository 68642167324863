import React, { useEffect, useState } from 'react';
import { Button, Col, Divider, Row, Skeleton, Typography } from 'antd';
import axios from 'axios';
import SAPAddress from 'components/SAPAddress';
import ChangePasswordModal from "components/ChangePasswordModal";



const Profile = () => {

	const [ fetching, setFetching ]                   = useState(true),
		  [ fetchSuccess, setFetchSuccess ]           = useState(false),
		  [ data, setData ]                           = useState({}),
		  [ showPasswordModal, setShowPasswordModal ] = useState(false);

	useEffect(() => {
		if(fetching) {
			axios.get(process.env.REACT_APP_API_URL + '/account/profile').then(res => {
				setFetchSuccess(true);
				setFetching(false);
				setData(res.data.profile_data);
			}).catch(err => {
				setFetchSuccess(false);
				setFetching(false);
			});
		}
	}, [ fetching, setFetching, setFetchSuccess ]);


	const handlePasswordModalClose = () => {
		setShowPasswordModal(false);
	};

	const handlePasswordModalOpen = () => {
		setShowPasswordModal(true);
	};


	return (
		<div className="ts-home-content-container">
			{fetching && <Skeleton active loading={fetching} />}

			{(!fetching && !fetchSuccess) &&
			<Typography.Text>There was an error loading your profile. Please refresh this page to try
				again.</Typography.Text>
			}

			{(!fetching && fetchSuccess) &&
			<Row gutter={[ 16, 16 ]}>
				<Col xs={24}>
					<div className="ts-portal-card">
						<Typography.Title level={4}>Your Info</Typography.Title>
						<Divider />

						<div>
							<Typography.Text strong>Name</Typography.Text>
						</div>
						<div className="login-alert">
							<Typography.Text>{data.user_name}</Typography.Text>
						</div>

						<div>
							<Typography.Text strong>Email Address</Typography.Text>
						</div>
						<div className="login-alert">
							<Typography.Text>{data.user_email}</Typography.Text>
						</div>

						<div>
							<Button onClick={handlePasswordModalOpen}>Change Password</Button>
						</div>
					</div>
				</Col>


				<Col xs={24} md={12}>
					<div className="ts-portal-card">
						<Typography.Title level={4}>Company Info</Typography.Title>
						<Divider />

						<div>
							<Typography.Text strong>Pure Account Designation</Typography.Text>
						</div>
						<div className="login-alert">
							<Typography.Text>{data.account_code}</Typography.Text>
						</div>

						<div>
							<Typography.Text strong>Company Name</Typography.Text>
						</div>
						<div className="login-alert">
							<Typography.Text>{data.company_name}</Typography.Text>
						</div>

						<div>
							<Typography.Text strong>FEIN</Typography.Text>
						</div>
						<div className="login-alert">
							<Typography.Text>{data.federal_tax_id}</Typography.Text>
						</div>

						<div>
							<Typography.Text strong>Account Balance</Typography.Text>
						</div>
						<div className="login-alert">
							<Typography.Text>{data.account_balance_formatted}</Typography.Text>
						</div>

						<div>
							<Typography.Text strong>Credit Limit</Typography.Text>
						</div>
						<div className="login-alert">
							<Typography.Text>{data.credit_limit_formatted}</Typography.Text>
						</div>

						<div>
							<Typography.Text strong>Phone Number</Typography.Text>
						</div>
						<div className="login-alert">
							<Typography.Text>{data.phone}</Typography.Text>
						</div>

						<div>
							<Typography.Text strong>Rebate Contact Primary</Typography.Text>
						</div>
						<div className="login-alert">
							<Typography.Text>{data.rebate_contact_primary}</Typography.Text>
						</div>

						<div>
							<Typography.Text strong>Rebate Contact Secondary</Typography.Text>
						</div>
						<div className="login-alert">
							<Typography.Text>{data.rebate_contact_secondary}</Typography.Text>
						</div>

						<div>
							<Typography.Text strong>Pure Rep</Typography.Text>
						</div>
						<div className="login-alert">
							<Typography.Text>{data.rep_name}</Typography.Text>
						</div>

						<div>
							<Typography.Text strong>Bank Name</Typography.Text>
						</div>
						<div className="login-alert">
							<Typography.Text>{data.bank_name}</Typography.Text>
						</div>

					</div>
				</Col>


				<Col xs={24} md={12}>
					<div className="ts-portal-card">
						<Typography.Title level={4}>Addresses</Typography.Title>
						<Divider />

						<div>
							<Typography.Text strong>
								Billing{Boolean(data.bill_name) && ` - ${data.bill_name}`}
							</Typography.Text>
						</div>
						<div className="login-alert">
							<SAPAddress
								street={data.bill_street}
								block={data.bill_block}
								building={data.bill_building}
								city={data.bill_city}
								state={data.bill_state}
								zip={data.bill_zip}
								country={data.bill_country}
							/>
						</div>

						{/*
						<div>
							<Typography.Text strong>
								Shipping{Boolean(data.ship_name) && ` - ${data.ship_name}`}
							</Typography.Text>
						</div>
						<div className="login-alert">
							<SAPAddress
								street={data.ship_street}
								block={data.ship_block}
								building={data.ship_building}
								city={data.ship_city}
								state={data.ship_state}
								zip={data.ship_zip}
								country={data.ship_country}
							/>
						</div>
						*/}
					</div>
				</Col>


			</Row>
			}

			<ChangePasswordModal
				visible={showPasswordModal}
				handleClose={handlePasswordModalClose}
			/>
		</div>


	);
};


export default Profile;