import React, { useCallback } from 'react';
import { FilePdfOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "base/store";



const CreditViewPDFButton = ({docEntry, docNum}) => {
	const dispatch = useDispatch();
	const hasPDF = useSelector(state => Boolean(state.credits.pdfs[docEntry]));


	const viewPDF = useCallback(() => {
		dispatch(actions.credits.viewPDF(docEntry, docNum));
	}, [ dispatch, docEntry, docNum ]);


	return (
		<Button
			icon={<FilePdfOutlined />}
			type={hasPDF ? 'primary' : 'default'}
			onClick={viewPDF}
		>
			View Credit
		</Button>
	)
};



export default CreditViewPDFButton;