import React from 'react';
import { Tag } from 'antd';
import CreditViewPDFButton from "./CreditViewPDFButton";
import makeAntColumns from "lib/makeAntColumns";



export const columns = makeAntColumns([
	{
		title: 'Credit Number',
		dataIndex: 'credit_number',
		sorter: 'number',
	},
	{
		title: 'BOL Number',
		dataIndex: 'bol_number',
		sorter: 'string',
	},
	{
		title: 'Date',
		dataIndex: 'credit_date',
		sorter: 'number',
		sortIndex: 'date_sortable',
	},
	{
		title: 'Total',
		dataIndex: 'credit_total_string',
		sorter: 'number',
		sortIndex: 'credit_total',
	},
	{
		title: 'Carrier',
		dataIndex: 'carrier_scac',
		sorter: 'string',
	},
	{
		title: 'Terminal',
		dataIndex: 'terminal_id',
		sorter: 'string',
	},
	{
		title: 'Destination',
		dataIndex: 'destination',
		sorter: 'string',
	},
	{
		title: 'Status',
		dataIndex: 'credit_status',
		sorter: 'string',
		render: (rowValue, row) => <Tag color={rowValue === 'Closed' ? 'orange' : 'green'}>{rowValue}</Tag>,
	},
	{
		title: 'View Credit',
		dataIndex: 'credit_key',
		align: 'center',
		width: 150,
		csvIgnore: true,
		render: (rowValue, row) => {
			return <CreditViewPDFButton docEntry={rowValue} docNum={row.credit_number} />;
		},
	},
]);




export default { columns };