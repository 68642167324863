import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import thunkMiddleware from "redux-thunk";
import layout, { actions as layoutActions } from './Layout/Layout.redux';
import auth, { actions as authActions } from './auth.redux';
import invoices, { actions as invoiceActions } from 'pages/Invoices/Invoices.redux';
import credits, { actions as creditActions } from 'pages/Credits/Credits.redux';
import efts, { actions as eftActions } from 'pages/EFTs/EFTs.redux';
import allocations, { actions as allocationsActions } from 'pages/Allocations/Allocations.redux';



export const history = createBrowserHistory();


const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const createRootReducer = (history) => combineReducers({
	router: connectRouter(history),
	layout,
	auth,
	invoices,
	credits,
	efts,
	allocations,
});



export const actions = {
	layout: layoutActions,
	auth: authActions,
	invoices: invoiceActions,
	credits: creditActions,
	efts: eftActions,
	allocations: allocationsActions,
};



const store = createStore(
	createRootReducer(history),
	composeEnhancers(
		applyMiddleware(
			routerMiddleware(history),
			thunkMiddleware,
		),
	),
);


export default store;