import React from 'react';
import { Col, Row, Typography } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Link } from "react-router-dom";



const Home = () => {
	return (

		<Row className="ts-home-content-container">
			<Col xs={24}>
				<div className="ts-portal-card">
					<Typography.Title level={4}><ExclamationCircleOutlined /> Not Found</Typography.Title>
					<Typography>
						Looks like you took a wrong turn. Click the Pure logo to go home, or use the navigation above
						to pick a page. If you've ended up here via our navigation, please let us know via
						our <Link to="/contact">contact</Link> page.
					</Typography>
				</div>
			</Col>
		</Row>

	)
};


export default Home;