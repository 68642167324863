import React from 'react';
import antTableToCSV from "lib/antTableToCSV";
import {saveAs} from 'file-saver';
import {Button} from 'antd';



const ExportCSVButton = ({
	label = 'Export CSV',
	fileName = 'exported-data.csv',
	columns = [],
	rows = [],
}) => {
	const handleExport = () => {
		let csv = antTableToCSV(columns, rows);
		let blob = new Blob([ csv ], { type: "text/csv" });
		saveAs(blob, fileName);
	};


	if(!rows.length || !columns.length) {
		return null;
	}

	return (
		<Button
			onClick={handleExport}
		>
			{label}
		</Button>
	)
};


export default ExportCSVButton;