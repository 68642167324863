import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Typography } from 'antd';
import { LockOutlined } from '@ant-design/icons';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';



const ResetPassword = () => {

	const [ submitted, setSubmit ]      = useState(false),
		  [ validToken, setValidToken ] = useState(null),
		  [ submitting, setSubmitting ] = useState(false);

	const token = useParams().token;


	const handleSubmit = async (values) => {
		setSubmitting(true);

		try {
			await axios.post(`${process.env.REACT_APP_API_URL}/user/reset_password`, {
				token: token,
				password: values.password,
			});

			setSubmit(true);
		} catch(err) {
			setSubmitting(false);
		}
	};

	useEffect(() => {
		async function checkToken() {
			if(validToken === null) {
				try {
					await axios.get(`${process.env.REACT_APP_API_URL}/user/check_reset_token/${token}`);
					setValidToken(true);
				} catch(err) {
					setValidToken(false);
				}
			}
		}

		checkToken();

	}, [ validToken, setValidToken, token ]);


	if(validToken === null) {
		return null;
	}


	if(validToken === false) {
		return (
			<div className="ts-rma-content-container">
				<div className="ts-portal-card">
					<Typography.Title level={4}><LockOutlined /> Reset Password</Typography.Title>
					<Typography.Text>
						The token provided is invalid or expired. Tokens are only valid for one hour. If you wish to
						initiate another password reset, click <Link to={'/forgot_password'} className="ts_underline_link">here</Link>.
					</Typography.Text>
				</div>
			</div>
		);
	}


	if(submitted) {
		return (
			<div className="ts-rma-content-container">
				<div className="ts-portal-card">
					<Typography.Title level={4}><LockOutlined /> Reset Password</Typography.Title>
					<Typography.Text>
						Your password has been changed successfully. Please click <Link to={'/'} className="ts_underline_link">here</Link> to
						log in.
					</Typography.Text>
				</div>
			</div>
		);
	}

	return (
		<div className="ts-rma-content-container">
			<div className="ts-portal-card">
				<Typography.Title level={4}><LockOutlined /> Reset Password</Typography.Title>
				<Typography.Text>
					Please enter the new password you wish to use, then click submit.
				</Typography.Text>

				<Form
					name="order_approval"
					layout="horizontal"
					labelCol={{ xs: { span: 24 }, lg: { span: 6 } }}
					wrapperCol={{ xs: { span: 24 }, lg: { span: 18 } }}
					labelAlign="left"
					onFinish={handleSubmit}
					className="ts-form-margin"
				>


					<Form.Item
						name="password"
						rules={[
							{ required: true, message: 'New Password is required' },
							{
								min: 10,
								message: 'Password must be at least 10 characters',
								validateTrigger: 'onSubmit',
							},
						]}
					>
						<Input.Password
							prefix={<LockOutlined className="site-form-item-icon" />}
							type="password"
							placeholder="New Password"
							disabled={submitting}
						/>
					</Form.Item>


					<Form.Item>
						<Button type="primary" htmlType="submit" loading={submitting}>
							Submit
						</Button>
					</Form.Item>
				</Form>

			</div>
		</div>


	);
};


export default ResetPassword;