import React from 'react';
import { Col, Menu, Row, Typography } from 'antd';
import { AuditOutlined, UserOutlined, CreditCardOutlined, DollarCircleOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom";



const Home = () => {
	return (

		<Row className="ts-home-content-container">


			<Col xs={24} md={12} lg={8} xl={6}>
				<div className="ts-portal-card">
					<Typography.Title level={4}><AuditOutlined /> Invoices</Typography.Title>
					<Menu className="ts-home-menu">
						<Menu.Item key="invoices_open"><Link to="/invoices/open">Open</Link></Menu.Item>
						<Menu.Item key="invoices_closed"><Link to="/invoices/closed">Closed</Link></Menu.Item>
						<Menu.Item key="invoices_view_all"><Link to="/invoices/all">View All</Link></Menu.Item>
					</Menu>
				</div>
			</Col>

			<Col xs={24} md={12} lg={8} xl={6}>
				<div className="ts-portal-card">
					<Typography.Title level={4}><CreditCardOutlined /> Credits</Typography.Title>
					<Menu className="ts-home-menu">
						<Menu.Item key="credits_open"><Link to="/credits/open">Open</Link></Menu.Item>
						<Menu.Item key="credits_closed"><Link to="/credits/closed">Closed</Link></Menu.Item>
						<Menu.Item key="credits_view_all"><Link to="/credits/all">View All</Link></Menu.Item>
					</Menu>
				</div>
			</Col>

			<Col xs={24} md={12} lg={8} xl={6}>
				<div className="ts-portal-card">
					<Typography.Title level={4}><DollarCircleOutlined /> EFTs</Typography.Title>
					<Menu className="ts-home-menu">
						<Menu.Item key="eft_view_all"><Link to="/eft">View All</Link></Menu.Item>
					</Menu>
				</div>
			</Col>

			<Col xs={24} md={12} lg={8} xl={6}>
				<div className="ts-portal-card">
					<Typography.Title level={4}><UserOutlined /> My Account</Typography.Title>
					<Menu className="ts-home-menu">
						<Menu.Item key="account_allocations"><Link to="/allocations">Allocations</Link></Menu.Item>
						<Menu.Item key="account_profile"><Link to="/profile">Profile</Link></Menu.Item>
						<Menu.Item key="account_log_out"><Link to="/logout">Log Out</Link></Menu.Item>
					</Menu>
				</div>
			</Col>


		</Row>

	);
};


export default Home;