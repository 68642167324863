import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import useDebounce from "hooks/useDebounce";
import { Input } from "antd";
import { actions } from 'base/store';
import filterArrayOfObjects from "lib/filterArrayOfObjects";



const CreditSearchInput = (props) => {
	const dispatch                      = useDispatch(),
		  [ searchTemp, setSearchTemp ] = useState(''),
		  debouncedSearchValue          = useDebounce(searchTemp, 250),
		  documents                      = useSelector(state => state.credits.documentsAll),

		  handleSearch                  = useCallback((e) => {
			  setSearchTemp(e.target.value);
		  }, [ setSearchTemp ]);


	useEffect(() => {
		dispatch(actions.credits.setState({ documents: filterArrayOfObjects(documents, debouncedSearchValue) }));
	}, [ dispatch, documents, debouncedSearchValue ]);

	return <Input placeholder="Search" onChange={handleSearch} {...props} />;
};



export default CreditSearchInput;