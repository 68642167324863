import React, { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { actions } from "base/store";
import { columns } from './Credits.tableConfig';
import { Col, Row, Table, Typography } from 'antd';
import CreditPDFModal from "./CreditPDFModal";
import CreditSearchInput from './CreditSearchInput';
import ExportCSVButton from "components/ExportCSVButton";
import moment from 'moment';


function getMode(mode) {
	switch((mode || '').toLowerCase()) {
		case 'open':
			return 'open';
		case 'closed':
			return 'closed';
		default:
			return 'all';
	}
}

const Credits = () => {
	let mode = getMode(useParams().mode);
	const dispatch = useDispatch();

	const documents = useSelector(state => state.credits.documents),
		  loading  = useSelector(state => state.credits.loading);


	const refreshDocuments = useCallback((mode) => {
		dispatch(actions.credits.refreshDocuments(mode));
	}, [ dispatch ]);

	const resetAll = useCallback(() => {
		dispatch(actions.credits.resetAll());
	}, [ dispatch ]);


	useEffect(() => {
		refreshDocuments(mode);
		return () => resetAll();
	}, [ refreshDocuments, resetAll, mode ]);


	return (
		<div className="ts-home-content-container ts-portal-card">
			<Typography.Title level={2}>{mode.charAt(0).toUpperCase() + mode.slice(1)} Credits</Typography.Title>
			<Row className="login-alert">
				<Col xs={24} md={12} lg={6}>
					<CreditSearchInput />
				</Col>

				<Col xs={24} md={12} lg={6}>
					<div className="ts_table_export_button">
						<ExportCSVButton
							columns={columns}
							rows={documents}
							fileName={`Pure Credits ${moment().format('MM-DD-YYYY')}.csv`}
						/>
					</div>
				</Col>
			</Row>

			<div className="ts_scroll_x">
				<Table
					columns={columns}
					dataSource={documents}
					loading={loading}
					pagination={{
						position: 'both',
						showSizeChanger: true,
						defaultPageSize: 10,
						pageSizeOptions: [ '5', '10', '20', '50', '100' ],
					}}
				/>
			</div>
			<CreditPDFModal />
		</div>
	);
};


export default Credits;