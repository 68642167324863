import React from 'react';
import {Tag} from 'antd';
import InvoiceViewPDFButton from "./InvoiceViewPDFButton";
import makeAntColumns from "lib/makeAntColumns";



export const columns = makeAntColumns([
	{
		title: 'Invoice Number',
		dataIndex: 'invoice_number',
		sorter: 'number',
	},
	{
		title: 'BOL Number',
		dataIndex: 'bol_number',
		sorter: 'string',
	},
	{
		title: 'Date',
		dataIndex: 'invoice_date',
		sorter: 'number',
		sortIndex: 'date_sortable',
	},
	{
		title: 'Due Date',
		dataIndex: 'due_date',
		sorter: 'number',
		sortIndex: 'due_date_sortable',
	},
	{
		title: 'Total',
		dataIndex: 'invoice_total_string',
		sorter: 'number',
		sortIndex: 'invoice_total',
	},
	{
		title: 'Balance',
		dataIndex: 'invoice_balance_string',
		sorter: 'number',
		sortIndex: 'invoice_balance',
	},
	{
		title: 'Carrier',
		dataIndex: 'carrier_scac',
		sorter: 'string',
	},
	{
		title: 'Terminal',
		dataIndex: 'terminal_id',
		sorter: 'string',
	},
	{
		title: 'Destination',
		dataIndex: 'destination',
		sorter: 'string',
	},
	{
		title: 'Status',
		dataIndex: 'invoice_status',
		sorter: 'string',
		render: (rowValue, row) => <Tag color={rowValue === 'Closed' ? 'orange' : 'green'}>{rowValue}</Tag>
	},
	{
		title: 'View Invoice',
		dataIndex: 'invoice_key',
		align: 'center',
		width: 150,
		csvIgnore: true,
		render: (rowValue, row) => {
			return <InvoiceViewPDFButton docEntry={rowValue} docNum={row.invoice_number} />
		}
	},
]);


export const closedColumns = makeAntColumns([
	{
		title: 'Invoice Number',
		dataIndex: 'invoice_number',
		sorter: 'number',
	},
	{
		title: 'BOL Number',
		dataIndex: 'bol_number',
		sorter: 'string',
	},
	{
		title: 'Date',
		dataIndex: 'invoice_date',
		sorter: 'number',
		sortIndex: 'date_sortable',
	},
	{
		title: 'Due Date',
		dataIndex: 'due_date',
		sorter: 'number',
		sortIndex: 'due_date_sortable',
	},
	{
		title: 'Total',
		dataIndex: 'invoice_total_string',
		sorter: 'number',
		sortIndex: 'invoice_total',
	},
	{
		title: 'Balance',
		dataIndex: 'invoice_balance_string',
		sorter: 'number',
		sortIndex: 'invoice_balance',
	},
	{
		title: 'Carrier',
		dataIndex: 'carrier_scac',
		sorter: 'string',
	},
	{
		title: 'Terminal',
		dataIndex: 'terminal_id',
		sorter: 'string',
	},
	{
		title: 'Destination',
		dataIndex: 'destination',
		sorter: 'string',
	},
	{
		title: 'Status',
		dataIndex: 'invoice_status',
		sorter: 'string',
		render: (rowValue, row) => <Tag color={rowValue === 'Closed' ? 'orange' : 'green'}>{rowValue}</Tag>
	},
	{
		title: 'Related Document',
		dataIndex: 'related_document',
		sorter: 'string',
	},
	{
		title: 'View Invoice',
		dataIndex: 'invoice_key',
		align: 'center',
		width: 150,
		csvIgnore: true,
		render: (rowValue, row) => {
			return <InvoiceViewPDFButton docEntry={rowValue} docNum={row.invoice_number} />
		}
	},
]);




export default { columns, closedColumns };