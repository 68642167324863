import React from 'react';
//import { Tag } from 'antd';
import EFTViewPDFButton from "./EFTViewPDFButton";
import makeAntColumns from "lib/makeAntColumns";



export const columns = makeAntColumns([
	{
		title: 'EFT Number',
		dataIndex: 'eft_number',
		sorter: 'number',
	},
	{
		title: 'Date',
		dataIndex: 'eft_date',
		sorter: 'number',
		sortIndex: 'date_sortable',
	},
	{
		title: 'Total',
		dataIndex: 'eft_total_string',
		sorter: 'number',
		sortIndex: 'eft_total',
	},
	/*{
		title: 'Canceled',
		dataIndex: 'eft_canceled',
		sorter: 'string',
		render: (rowValue, row) => <Tag color={rowValue === 'Yes' ? 'orange' : 'green'}>{rowValue}</Tag>,
	},*/
	{
		title: 'View EFT',
		dataIndex: 'eft_key',
		align: 'center',
		width: 150,
		csvIgnore: true,
		render: (rowValue, row) => {
			return <EFTViewPDFButton docEntry={rowValue} docNum={row.eft_number} />;
		},
	},
]);




export default { columns };