import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { actions } from "base/store";
import { columns } from './EFTs.tableConfig';
import { Col, Row, Table, Typography } from 'antd';
import EFTPDFModal from "./EFTPDFModal";
import EFTSearchInput from './EFTSearchInput';
import ExportCSVButton from "components/ExportCSVButton";
import moment from "moment";



const EFTs = () => {
	const dispatch = useDispatch();

	const documents = useSelector(state => state.efts.documents),
		  loading   = useSelector(state => state.efts.loading);


	const refreshDocuments = useCallback(() => {
		dispatch(actions.efts.refreshDocuments());
	}, [ dispatch ]);

	const resetAll = useCallback(() => {
		dispatch(actions.efts.resetAll());
	}, [ dispatch ]);


	useEffect(() => {
		refreshDocuments();
		return () => resetAll();
	}, [ refreshDocuments, resetAll ]);


	return (
		<div className="ts-home-content-container ts-portal-card">
			<Typography.Title level={2}>EFTs</Typography.Title>
			<Row className="login-alert">
				<Col xs={24} md={12} lg={6}>
					<EFTSearchInput />
				</Col>

				<Col xs={24} md={12} lg={6}>
					<div className="ts_table_export_button">
						<ExportCSVButton
							columns={columns}
							rows={documents}
							fileName={`Pure EFTs ${moment().format('MM-DD-YYYY')}.csv`}
						/>
					</div>
				</Col>
			</Row>

			<div className="ts_scroll_x">
				<Table
					columns={columns}
					dataSource={documents}
					loading={loading}
					pagination={{
						position: 'both',
						showSizeChanger: true,
						defaultPageSize: 10,
						pageSizeOptions: [ '5', '10', '20', '50', '100' ],
					}}
				/>
			</div>
			<EFTPDFModal />
		</div>
	);
};


export default EFTs;