import React from 'react';
import { Typography } from "antd";



const { Text } = Typography;


const SAPAddress = ({ street, block, building, city, state, zip, country }) => {
	return (
		<>
			{Boolean(street) && <div><Text>{street}</Text></div>}
			{Boolean(block) && <div><Text>{block}</Text></div>}
			{Boolean(building) && <div><Text>{building}</Text></div>}
			<div>
				{Boolean(city) && <Text>{city}</Text>}
				{(Boolean(city) && Boolean(state)) && <Text>, </Text>}
				{Boolean(state) && <Text>{state}</Text>}
				{Boolean(zip) && <Text> {zip}</Text>}
			</div>
			{Boolean(country) && <div><Text>{country}</Text></div>}
		</>
	);
};


export default SAPAddress;