import React from 'react';
import createRoute from './Router/createRoute';
import Home from 'pages/Home';
import Invoices from 'pages/Invoices';
import Credits from 'pages/Credits';
import EFTs from 'pages/EFTs';
import Logout from 'pages/Logout';
import ForgotPassword from 'pages/ForgotPassword';
import ResetPassword from 'pages/ResetPassword';
import Profile from "pages/Profile";
import Allocations from "pages/Allocations";



export const routes = [

	createRoute({
		path: '/logout',
		exact: true,
		render: <Logout />,
		title: 'Log Out',
		private: true,
	}),

	createRoute({
		path: '/invoices/:mode?',
		exact: true,
		render: <Invoices />,
		title: 'Invoices',
		private: true,
	}),

	createRoute({
		path: '/credits/:mode?',
		exact: true,
		render: <Credits />,
		title: 'Credits',
		private: true,
	}),

	createRoute({
		path: '/eft',
		exact: true,
		render: <EFTs />,
		title: 'EFTs',
		private: true,
	}),

	createRoute({
		path: '/allocations',
		exact: true,
		render: <Allocations />,
		title: 'Allocations',
		private: true,
	}),

	createRoute({
		path: '/profile',
		exact: true,
		render: <Profile />,
		title: 'Profile',
		private: true,
	}),

	createRoute({
		path: '/forgot_password',
		exact: true,
		render: <ForgotPassword />,
		title: 'Forgot Password',
		private: false,
	}),


	createRoute({
		path: '/reset_password/:token',
		exact: true,
		render: <ResetPassword />,
		title: 'Reset Password',
		private: false,
	}),

	createRoute({
		path: '/',
		exact: true,
		render: <Home />,
		title: 'Home',
		private: true,
	}),

];