import axios from 'axios';



const initialState = {
	loading: false,
	error: false,
	rows: [],
};


export const types = {
	SET_STATE: 'ALLOCATIONS/SET_STATE',
};




export default (state = initialState, action) => {
	switch(action.type) {
		case types.SET_STATE:
			return { ...state, ...action.data };
		default:
			return state;
	}
}



export const actions = {
	setState: (data) => ({ type: types.SET_STATE, data }),


	resetAllocations: () => ({ type: types.SET_STATE, data: { ...initialState } }),


	refreshAllocations: () => async (dispatch) => {
		dispatch({ type: types.SET_STATE, data: { loading: true, error: false, rows: [] } });

		try {
			let invoiceRequest = await axios.get(`${process.env.REACT_APP_API_URL}/allocations`, { timeout: 180000 });

			dispatch({
				type: types.SET_STATE, data: {
					loading: false,
					rows: invoiceRequest.data.allocations,
					error: false,
				},
			});
		} catch(err) {
			dispatch({ type: types.SET_STATE, data: { loading: false, error: true, rows: [] } });
		}
	},

};